import React from 'react';
import { Col, Row } from 'antd';
import { SectionTitle } from 'components/Section';
import SeeMoreCard from 'components/SeeMoreCard';
import Item from './Item';

const GRID_COLS = 24;

type ArticlesType = {
    items: Article[];
    type: 'article';
};

type WhitePapersType = {
    items: WhitePaper[];
    type: 'white-paper';
};

type GalleriesType = {
    items: Gallery[];
    type: 'gallery';
};

type VideosType = {
    items: Video[];
    type: 'video';
};
type EventType = {
    items: Occurrence[];
    type: 'event';
};

type ItemsType = ArticlesType | WhitePapersType | GalleriesType | VideosType | EventType;

type MainProps = {
    title?: string | JSX.Element;
    noImages?: boolean;
    noTeaser?: boolean;
    maxItems?: number;
    maxItemsInRow?: number;
    maxItemsInRowMobile?: number;
    showShowMore?: boolean;
    url?: string;
};

type Props = MainProps & ItemsType;

export const CardGrid = ({
    items,
    type,
    title,
    noImages = false,
    noTeaser = false,
    maxItems = 4,
    maxItemsInRow = 4,
    maxItemsInRowMobile = 1,
    showShowMore = false,
    url
}: Props) => {
    const size = {
        xs: Math.floor(GRID_COLS / maxItemsInRowMobile),
        md: Math.floor(GRID_COLS / maxItemsInRow)
    };

    const slicedItems = items?.slice(0, maxItems) || [];
    return (
        <>
            {title && (
                <Row>
                    <Col span={24}>
                        <SectionTitle title={title} url={url} />
                    </Col>
                </Row>
            )}
            <Row key={`section-${title}-grid`} gutter={[16, 30]}>
                {slicedItems.map((item) => {
                    return (
                        <Item
                            type={type}
                            key={`section-${title}-item-${item.seoSlug}`}
                            $item={item}
                            size={size}
                            noImages={noImages}
                            noTeaser={noTeaser}
                        />
                    );
                })}
                {showShowMore && (
                    <Col {...size}>
                        <SeeMoreCard url={url} height={178} width={316} />
                    </Col>
                )}
            </Row>
        </>
    );
};

export default CardGrid;
